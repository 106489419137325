<template>
  <div class="wrapper" :class="[{ 'nav-open': $sidebar.showSidebar }]">
    <notifications></notifications>
    <side-bar :active-color="sidebarBackground" :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor">
      <UserMenu :title="user_name"></UserMenu>

      <template slot="links">
        <sidebar-item v-for="(item, index) in sidebarItems" :key="index" :link="item.link">
          <sidebar-item v-for="(subItem, subIndex) in item.children" :key="subIndex"
            v-if="!subItem.condition || (subItem.condition && subItem.condition())" :link="subItem.link" />
            <!--v-if="isScreenAllowed(subItem.name)" :link="subItem.link" /-->
          </sidebar-item>
      </template>
    </side-bar>
    
    <div class="main-panel">
      <top-navbar v-if="$route.name.toLowerCase() !== 'map'"></top-navbar>

      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="$route.meta.showFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar'
  import 'perfect-scrollbar/css/perfect-scrollbar.css'
  import { mapState, mapActions, mapGetters } from 'vuex'

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`)
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className)
      }, 100)
    }
  }

  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import UserMenu from './Extra/UserMenu.vue'
  import { ZoomCenterTransition } from 'vue2-transitions'

  export default {
    components: {
      TopNavbar,
      ContentFooter,
      UserMenu,
      ZoomCenterTransition
    },
    data() {
      return {
        sidebarBackgroundColor: 'black',
        sidebarBackground: 'danger',
        sidebarBackgroundImage: './img/sidebar-2.jpg',
        sidebarMini: true,
        sidebarImg: true,

        sidebarItems: [
          { link: { name: 'Home', icon: 'home', path: '/home' } },
          {
            link: { name: 'Inventory', icon: 'admin_panel_settings' },
            children: [
              { link: { name: 'Regions', path: '/inventory_data/regions' }, condition: () => this.isScreenAllowed('Regions') },
              { link: { name: 'Deu', path: '/inventory_data/deu' }, condition: () => this.isScreenAllowed('Deu')},
              { link: { name: 'Roads', path: '/inventory_data/roads' }, condition: () => this.isScreenAllowed('Roads') },
              { link: { name: 'Sections', path: '/inventory_data/sections' }, condition: () => this.amIAdmin && this.amIFromRMD },
              { link: { name: 'traffic_sites', path: '/inventory_data/traffic' } },
              //{ link: { name: 'GPS data', path: '/condition_data/gps' } },
              { link: { name: 'Import', icon: 'upload', path: '/inventory_data/import' } }
            ]
          },
          {
            link: { name: 'Survey', icon: 'query_stats' },
            children: [
              { link: { name: 'Condition_Data', path: '/condition_data/condition' } },
              //{ link: { name: 'traffic_data', path: '/condition_data/traffic' } },
              { link: { name: 'Import', icon: 'upload', path: '/condition_data/upload' } },
            ]
          },
          {
            link: { name: 'planning', icon: 'money' },
            condition: () => this.amIAdmin && this.amIFromRMD,
            children: [
              { link: { name: 'generate_treatment', path: '/planning/generate_treatment' }, condition: () => this.amIAdmin && this.amIFromRMD },
              { link: { name: 'prepare_plan', path: '/planning/prepare_plan' }, condition: () => this.amIAdmin && this.amIFromRMD },
              { link: { name: 'title_List', path: '/planning/title_list' }, condition: () => this.amIAdmin && this.amIFromRMD }
            ]
          },
          { link: { name: 'bridges_and_tunnels', icon: 'terrain', path: '/bridges-tunnels-tables' } },
          { link: { name: 'Map', icon: 'location_on', path: '/map' }, class: "hide-sidebar-item" },
          { link: { name: 'Reports', icon: 'print', path: '/reports_panel' } },
          { link: { name: 'administrative_tasks', icon: 'settings', path: '/admin_panel' }, condition: () => this.amIAdmin && this.amIFromRMD },
        ]
      }
    },

    methods: {
      ...mapActions({
      loadPermissions: 'GET_USER_PERMISSIONS',
    }),

      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },

      minimizeSidebar() {
        if (this.$sidebar) {
          this.$sidebar.toggleMinimize()
        }
      }
    },

    async mounted() {
      let docClasses = document.body.classList
      let isWindows = navigator.platform.startsWith('Win')
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar')
        initScrollbar('sidebar-wrapper')
        initScrollbar('main-panel')

        docClasses.add('perfect-scrollbar-on')
      } else {
        docClasses.add('perfect-scrollbar-off')
      }

      if (!this.userPermissionsLoaded) {
        await this.loadPermissions(this.me.id);
      }

    },

    watch: {
      sidebarMini() {
        this.minimizeSidebar()
      }
    },

    computed: {
      ...mapState({ me: (state) => state.Login.me }),
      ...mapGetters(['amIAdmin', 'amIFromRMD', 'isScreenAllowed']),
      user_name() {
        return `${this.me.first_name} ${this.me.last_name}`
      }
    }
  }
</script>
<style lang="scss">
$scaleSize: 0.95;

@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

@media (max-width: 992px) {
  .hide-sidebar-item {
    display: none;
  }
}
</style>
